import { useEffect, useRef, useState } from "react";
import {
  DateTimeNumericAxis,
  DefaultPaletteProvider,
  ELabelPlacement,
  EStrokePaletteMode, 
  EllipsePointMarker,
  FastBubbleRenderableSeries,
  FastLineRenderableSeries,
  HorizontalLineAnnotation,
  IPointMetadata, 
  MouseWheelZoomModifier,
  NumericAxis,
  SciChartSurface,
  TPointMarkerArgb,
  XyDataSeries,
  XyzDataSeries,
  ZoomExtentsModifier,
  ZoomPanModifier,
  parseColorToUIntArgb
} from "scichart";
import { SciChartReact } from "scichart-react";
import { toast } from 'react-toastify';
import { LoadingOutlined } from '@ant-design/icons';
import { MovingAverage } from "../../../../../../../../shared/utils/trade.util";
import { FilterOperator, OrderExnessItemOutput, SortOrder, useGetListPriceLazyQuery } from "../../../../../graphql/schema";
import { Spin } from "antd";
import 'react-toastify/dist/ReactToastify.css';

SciChartSurface.loadWasmFromCDN();
SciChartSurface.UseCommunityLicense();

interface OrderChartProps {
  order: OrderExnessItemOutput,
  durationInSecond: number;
}

enum CommandPointType {
  OPEN = 0,
  CLOSE = 1,
}

class BubblePaletteProvider extends DefaultPaletteProvider {
  constructor() {
    super();
    this.strokePaletteMode = EStrokePaletteMode.SOLID;
  }

  // This function is called for every data-point.
  overridePointMarkerArgb(xValue: number, yValue: number, index: number, opacity?: number | undefined, metadata?: IPointMetadata | undefined): TPointMarkerArgb {
    if (index === CommandPointType.OPEN) {
      return { stroke: parseColorToUIntArgb('#FF8C00'), fill: parseColorToUIntArgb('#FF8C00') }
    }
    if (index === CommandPointType.CLOSE) {
      return { stroke: parseColorToUIntArgb('#FF8C00'), fill: parseColorToUIntArgb('#FF8C00') }
    }
    return { stroke: parseColorToUIntArgb('#FF8C00'), fill: parseColorToUIntArgb('#FF8C00') }
  }
}

function OrderChart(props: OrderChartProps) {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const pricesDataSeriesRef = useRef<XyDataSeries>();
  const maRedDataSeriesRef = useRef<XyDataSeries>();
  const maYellowDataSeriesRef = useRef<XyDataSeries>();
  const pointDataSeriesRef = useRef<XyzDataSeries>();
  const [getListPrice] = useGetListPriceLazyQuery();

  useEffect(() => {
    loadChartData();
  }, [props.order]);

  const loadChartData = () => {
    const timeOffset = new Date().getTimezoneOffset();
    getListPrice({
      variables: {
        input: {
          filter: {
            and: [
              {
                property: 'instrument',
                value: props.order.instrument,
                operator: FilterOperator.eq,
              },
              {
                property: 't',
                value: [
                  new Date(props.order.openTime).getTime() - 60 * 60 * 1000,
                  new Date(props.order.openTime).getTime() + 60 * 60 * 1000,
                ],
                operator: FilterOperator.between,
              },
            ]
          },
          pageSize: 99999,
          sort: [{
            order: SortOrder.ASC,
            property: 't'
          }]
        }
      },
    }).then(({ data, error }) => {
      if (!error && data) {
        const maRedList = new MovingAverage(props.durationInSecond * 7, 'buy');
        const maYellowList = new MovingAverage(props.durationInSecond * 25, 'buy');
        pricesDataSeriesRef.current?.clear();
        maRedDataSeriesRef.current?.clear();
        maYellowDataSeriesRef.current?.clear();
        pointDataSeriesRef.current?.clear();
        if (data.getListPrice.items.length === 0) {
          return toast.warn('Data is empty');
        }
        for (const price of data.getListPrice.items) {
          maRedList.updatePrice({ t: price.t, sell: price.a, buy: price.b });
          maYellowList.updatePrice({ t: price.t, sell: price.a, buy: price.b });
          if (maRedList.isFull()) {
            const maRedNewPoint = maRedList.value()!;
            if (maRedDataSeriesRef.current) {
              maRedDataSeriesRef.current.append(
                (Number(maRedNewPoint.t) - timeOffset * 60 * 1000) / 1000,
                maRedNewPoint.price
              )
            }
          }
          if (maYellowList.isFull()) {
            const maYellowNewPoint = maYellowList.value()!;
            if (maYellowDataSeriesRef.current) {
              maYellowDataSeriesRef.current.append(
                (Number(maYellowNewPoint.t) - timeOffset * 60 * 1000) / 1000,
                maYellowNewPoint.price
              )
            }
          }
          pricesDataSeriesRef.current?.append(
            (Number(price.t) - timeOffset * 60 * 1000) / 1000,
            price.a
          )
        }

        pointDataSeriesRef.current?.append(
          (new Date(props.order.openTime).getTime() - timeOffset * 60 * 1000) / 1000,
          props.order.openPrice,
          30
        );

        pointDataSeriesRef.current?.append(
          (new Date(props.order.closeTime).getTime() - timeOffset * 60 * 1000) / 1000,
          props.order.closePrice,
          30,
        );
      } else {
        toast.error('Failed to load data', {
          data: error,
        });
      }
    }).finally(() => {
      //setIsLoadingData(false) 
    });
  }

  return (
    <>
      <>
        {isLoadingData
          ? (
            <div className="w-full h-full flex justify-center items-center">
              <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} />
            </div>
          )
          : (
            <SciChartReact
              style={{ width: '100%', height: "100%" }}
              initChart={async function (rootElement) {
                const { sciChartSurface, wasmContext } = await SciChartSurface.create(rootElement);
                const xAxis = new DateTimeNumericAxis(wasmContext, { autoTicks: true });
                const yAxis = new NumericAxis(wasmContext);

                sciChartSurface.xAxes.add(xAxis);
                sciChartSurface.yAxes.add(yAxis);

                const priceSeries = new FastLineRenderableSeries(wasmContext, { stroke: "#ffffff", strokeThickness: 4, opacity: 0.5 });
                const priceData = new XyDataSeries(wasmContext, { dataSeriesName: "Price" });
                pricesDataSeriesRef.current = priceData;
                priceSeries.dataSeries = priceData;

                const maYellowSeries = new FastLineRenderableSeries(wasmContext, { stroke: "yellow", strokeThickness: 1 });
                const maYellowData = new XyDataSeries(wasmContext, { dataSeriesName: "MA25" });
                maYellowDataSeriesRef.current = maYellowData;
                maYellowSeries.dataSeries = maYellowData;

                const maRedSeries = new FastLineRenderableSeries(wasmContext, { stroke: "#ff6257", strokeThickness: 1 });
                const maRedData = new XyDataSeries(wasmContext, { dataSeriesName: "MA7" });
                maRedDataSeriesRef.current = maRedData;
                maRedSeries.dataSeries = maRedData;

                const pointSeries = new FastBubbleRenderableSeries(wasmContext, {
                  opacity: 0.6,
                  pointMarker: new EllipsePointMarker(wasmContext, {
                    width: 64,
                    height: 64,
                    strokeThickness: 4,
                    stroke: '#ffffff',
                    fill: "red",
                  }),
                  paletteProvider: new BubblePaletteProvider()
                })
                const pointData = new XyzDataSeries(wasmContext, { dataSeriesName: "Order" });
                pointDataSeriesRef.current = pointData;
                pointSeries.dataSeries = pointData;


                sciChartSurface.renderableSeries.add(maRedSeries, maYellowSeries, priceSeries, pointSeries);

                sciChartSurface.annotations.add(new HorizontalLineAnnotation({
                  y1: props.order.sl || 0,
                  stroke: "white",
                  axisLabelFill: "white",
                  labelPlacement: ELabelPlacement.TopRight,
                  labelValue: `SL: ${props.order.sl} USD`,
                  showLabel: true,
                }));
                sciChartSurface.annotations.add(new HorizontalLineAnnotation({
                  y1: props.order.openPrice,
                  stroke: "yellow",
                  axisLabelFill: "yellow",
                  labelPlacement: ELabelPlacement.BottomRight,
                  labelValue: `Open: ${props.order.openPrice} USD`,
                  showLabel: true
                }));
                sciChartSurface.annotations.add(new HorizontalLineAnnotation({
                  y1: props.order.closePrice,
                  stroke: "yellow",
                  axisLabelFill: "yellow",
                  labelPlacement: ELabelPlacement.BottomRight,
                  labelValue: `Close: ${props.order.closePrice} USD`,
                  showLabel: true
                }));

                sciChartSurface.chartModifiers.add(
                  new ZoomPanModifier({ enableZoom: true }),
                  new MouseWheelZoomModifier(),
                  new ZoomExtentsModifier()
                );

                return { sciChartSurface };
              }} />
          )}
      </>
    </>
  );
}

export default OrderChart;