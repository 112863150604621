import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
};

export type AccounOutput = {
  __typename?: 'AccounOutput';
  accountNumber: Scalars['String']['output'];
  balance?: Maybe<Scalars['Float']['output']>;
  config?: Maybe<AccountConfigOutput>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isReal?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AccountConfigOutput = {
  __typename?: 'AccountConfigOutput';
  dSL: Scalars['Float']['output'];
  dTP: Scalars['Float']['output'];
  dValueClose: Scalars['Float']['output'];
  dValueOpen: Scalars['Float']['output'];
  durationInSecond: Scalars['Int']['output'];
  instrument: Instrument;
  profitRate: Scalars['Float']['output'];
  profitStep: Scalars['Float']['output'];
  profitThreshold: Scalars['Float']['output'];
  sideWayOptions?: Maybe<SideWayOptions>;
  volume: Scalars['Float']['output'];
};

export type CreateTestInput = {
  count?: InputMaybe<Scalars['Int']['input']>;
  desc?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rate?: InputMaybe<Scalars['Float']['input']>;
};

export type CreateTestOutput = {
  __typename?: 'CreateTestOutput';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['Int']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<TestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type CreateTradeOrderInput = {
  exchange: Exchange;
  exnessAccountId: Scalars['String']['input'];
  instrument: Instrument;
  maxTime: Scalars['Int']['input'];
  sl: Scalars['Float']['input'];
  targetPrice: Scalars['Float']['input'];
  tp: Scalars['Float']['input'];
  type: TradeOrderType;
  volume: Scalars['Float']['input'];
};

export type CreateTradeOrderOutput = {
  __typename?: 'CreateTradeOrderOutput';
  orderId: Scalars['String']['output'];
};

export type DeleteOneTestOutput = {
  __typename?: 'DeleteOneTestOutput';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['Int']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<TestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export enum Exchange {
  EXNESS = 'EXNESS'
}

export type Filter = {
  and?: InputMaybe<Array<Filter>>;
  operator?: InputMaybe<FilterOperator>;
  or?: InputMaybe<Array<Filter>>;
  property?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export enum FilterOperator {
  between = 'between',
  eq = 'eq',
  gt = 'gt',
  in = 'in',
  like = 'like',
  lt = 'lt',
  not = 'not'
}

export type GetListAccountOutput = {
  __typename?: 'GetListAccountOutput';
  accounts: Array<AccounOutput>;
};

export type GetListOrderExnessInput = {
  exnessAccountId: Scalars['String']['input'];
  fromTime: Scalars['DateTime']['input'];
  toTime: Scalars['DateTime']['input'];
};

export type GetListOrderExnessOutput = {
  __typename?: 'GetListOrderExnessOutput';
  orders: Array<OrderExnessItemOutput>;
};

export type GetListPriceInput = {
  filter?: InputMaybe<Filter>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<Sort>>;
};

export type GetListPriceItemOutput = {
  __typename?: 'GetListPriceItemOutput';
  a: Scalars['Float']['output'];
  b: Scalars['Float']['output'];
  instrument?: Maybe<Instrument>;
  t: Scalars['Float']['output'];
};

export type GetListPriceOutput = {
  __typename?: 'GetListPriceOutput';
  items: Array<GetListPriceItemOutput>;
  pagination: Pages;
};

export type GetListTestInput = {
  filter?: InputMaybe<Filter>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<Sort>>;
};

export type GetListTestItemOutput = {
  __typename?: 'GetListTestItemOutput';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['Int']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  status?: Maybe<TestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type GetListTestOutput = {
  __typename?: 'GetListTestOutput';
  items: Array<GetListTestItemOutput>;
  pagination: Pages;
};

export type GetListTradeOrderInput = {
  filter?: InputMaybe<Filter>;
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Array<Sort>>;
};

export type GetListTradeOrderItemOutput = {
  __typename?: 'GetListTradeOrderItemOutput';
  data: Scalars['JSONObject']['output'];
  id: Scalars['String']['output'];
  status: OrderStatus;
};

export type GetListTradeOrderOutput = {
  __typename?: 'GetListTradeOrderOutput';
  items: Array<GetListTradeOrderItemOutput>;
  pagination: Pages;
};

export type GetOneTestOutput = {
  __typename?: 'GetOneTestOutput';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['Int']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<TestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export enum Instrument {
  BTCUSD = 'BTCUSD',
  USOIL = 'USOIL',
  XAUUSD = 'XAUUSD'
}

export type LoginExnessInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  email: Scalars['String']['output'];
  exnessCookies?: Maybe<Array<Scalars['JSONObject']['output']>>;
  id: Scalars['Int']['output'];
  loggedExnessAt?: Maybe<Scalars['DateTime']['output']>;
  token: Scalars['String']['output'];
  tokenType: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelTradeOrder: Scalars['Boolean']['output'];
  checkToken: Scalars['Boolean']['output'];
  createTest: CreateTestOutput;
  deleteOneTest: DeleteOneTestOutput;
  login: LoginOutput;
  loginExness: Scalars['Boolean']['output'];
  openTradeOrder: CreateTradeOrderOutput;
  signup: SignupOutput;
  updateOneTest: UpdateOneTestOutput;
};


export type MutationCancelTradeOrderArgs = {
  orderId: Scalars['Float']['input'];
};


export type MutationCheckTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationCreateTestArgs = {
  input: CreateTestInput;
};


export type MutationDeleteOneTestArgs = {
  testId: Scalars['Float']['input'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLoginExnessArgs = {
  input: LoginExnessInput;
};


export type MutationOpenTradeOrderArgs = {
  input: CreateTradeOrderInput;
};


export type MutationSignupArgs = {
  input: SignupInput;
};


export type MutationUpdateOneTestArgs = {
  input: UpdateOneTestInput;
};

export type OrderExnessItemOutput = {
  __typename?: 'OrderExnessItemOutput';
  accountId: Scalars['String']['output'];
  closePrice: Scalars['Float']['output'];
  closeTime: Scalars['DateTime']['output'];
  commission: Scalars['Float']['output'];
  instrument: Instrument;
  openPrice: Scalars['Float']['output'];
  openTime: Scalars['DateTime']['output'];
  orderId: Scalars['String']['output'];
  profit: Scalars['Float']['output'];
  sl?: Maybe<Scalars['Float']['output']>;
  tp?: Maybe<Scalars['Float']['output']>;
  type: TradeOrderType;
  volume: Scalars['Float']['output'];
};

export enum OrderStatus {
  CANCEL = 'CANCEL',
  OPENED = 'OPENED',
  WAITING = 'WAITING'
}

export type Pages = {
  __typename?: 'Pages';
  currPage: Scalars['Int']['output'];
  itemTotal: Scalars['Int']['output'];
  nextPage?: Maybe<Scalars['Int']['output']>;
  pageSize: Scalars['Int']['output'];
  pageTotal: Scalars['Int']['output'];
  prevPage?: Maybe<Scalars['Int']['output']>;
};

export type Query = {
  __typename?: 'Query';
  getListAccountExness: GetListAccountOutput;
  getListOrderExness: GetListOrderExnessOutput;
  getListPrice: GetListPriceOutput;
  getListTest: GetListTestOutput;
  getListTradeOrder: GetListTradeOrderOutput;
  getOneTest: GetOneTestOutput;
};


export type QueryGetListOrderExnessArgs = {
  input: GetListOrderExnessInput;
};


export type QueryGetListPriceArgs = {
  input: GetListPriceInput;
};


export type QueryGetListTestArgs = {
  input: GetListTestInput;
};


export type QueryGetListTradeOrderArgs = {
  input: GetListTradeOrderInput;
};


export type QueryGetOneTestArgs = {
  testId: Scalars['Int']['input'];
};

export type SideWayOptions = {
  __typename?: 'SideWayOptions';
  dOver: Scalars['Float']['output'];
  dPriceLimit: Scalars['Float']['output'];
  dTimeSecondLimit: Scalars['Float']['output'];
};

export type SignupInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SignupOutput = {
  __typename?: 'SignupOutput';
  email: Scalars['String']['output'];
  id: Scalars['Int']['output'];
};

export type Sort = {
  order: SortOrder;
  property: Scalars['String']['input'];
};

export enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum TestStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS'
}

export enum TradeOrderType {
  BUY = 'BUY',
  SELL = 'SELL'
}

export type UpdateOneTestInput = {
  count?: InputMaybe<Scalars['Int']['input']>;
  desc?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['Int']['input'];
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rate?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TestStatus>;
};

export type UpdateOneTestOutput = {
  __typename?: 'UpdateOneTestOutput';
  count?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['Int']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deletedBy?: Maybe<Scalars['Int']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<TestStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<Scalars['Int']['output']>;
};

export type CheckLoginMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type CheckLoginMutation = { __typename?: 'Mutation', checkToken: boolean };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'LoginOutput', id: number, email: string, tokenType: string, token: string, exnessCookies?: Array<any> | null, loggedExnessAt?: any | null } };

export type GetListAccountExnessQueryVariables = Exact<{ [key: string]: never; }>;


export type GetListAccountExnessQuery = { __typename?: 'Query', getListAccountExness: { __typename?: 'GetListAccountOutput', accounts: Array<{ __typename?: 'AccounOutput', accountNumber: string, name?: string | null, balance?: number | null, isReal?: boolean | null, isActive?: boolean | null, isHidden?: boolean | null, isDeleted?: boolean | null, config?: { __typename?: 'AccountConfigOutput', durationInSecond: number, dValueOpen: number, dValueClose: number, profitThreshold: number, profitRate: number, profitStep: number, dSL: number, dTP: number, volume: number, instrument: Instrument, sideWayOptions?: { __typename?: 'SideWayOptions', dPriceLimit: number, dTimeSecondLimit: number, dOver: number } | null } | null }> } };

export type GetListOrderExnessQueryVariables = Exact<{
  input: GetListOrderExnessInput;
}>;


export type GetListOrderExnessQuery = { __typename?: 'Query', getListOrderExness: { __typename?: 'GetListOrderExnessOutput', orders: Array<{ __typename?: 'OrderExnessItemOutput', orderId: string, accountId: string, type: TradeOrderType, openTime: any, openPrice: number, closeTime: any, closePrice: number, volume: number, profit: number, sl?: number | null, tp?: number | null, commission: number, instrument: Instrument }> } };

export type GetListPriceQueryVariables = Exact<{
  input: GetListPriceInput;
}>;


export type GetListPriceQuery = { __typename?: 'Query', getListPrice: { __typename?: 'GetListPriceOutput', items: Array<{ __typename?: 'GetListPriceItemOutput', a: number, b: number, t: number, instrument?: Instrument | null }>, pagination: { __typename?: 'Pages', prevPage?: number | null, currPage: number, nextPage?: number | null, itemTotal: number, pageTotal: number, pageSize: number } } };


export const CheckLoginDocument = gql`
    mutation CheckLogin($token: String!) {
  checkToken(token: $token)
}
    `;
export type CheckLoginMutationFn = Apollo.MutationFunction<CheckLoginMutation, CheckLoginMutationVariables>;

/**
 * __useCheckLoginMutation__
 *
 * To run a mutation, you first call `useCheckLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCheckLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [checkLoginMutation, { data, loading, error }] = useCheckLoginMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useCheckLoginMutation(baseOptions?: Apollo.MutationHookOptions<CheckLoginMutation, CheckLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CheckLoginMutation, CheckLoginMutationVariables>(CheckLoginDocument, options);
      }
export type CheckLoginMutationHookResult = ReturnType<typeof useCheckLoginMutation>;
export type CheckLoginMutationResult = Apollo.MutationResult<CheckLoginMutation>;
export type CheckLoginMutationOptions = Apollo.BaseMutationOptions<CheckLoginMutation, CheckLoginMutationVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    id
    email
    tokenType
    token
    exnessCookies
    loggedExnessAt
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const GetListAccountExnessDocument = gql`
    query GetListAccountExness {
  getListAccountExness {
    accounts {
      accountNumber
      name
      balance
      isReal
      isActive
      isHidden
      isDeleted
      config {
        durationInSecond
        dValueOpen
        dValueClose
        profitThreshold
        profitRate
        profitStep
        dSL
        dTP
        volume
        instrument
        sideWayOptions {
          dPriceLimit
          dTimeSecondLimit
          dOver
        }
      }
    }
  }
}
    `;

/**
 * __useGetListAccountExnessQuery__
 *
 * To run a query within a React component, call `useGetListAccountExnessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListAccountExnessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListAccountExnessQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetListAccountExnessQuery(baseOptions?: Apollo.QueryHookOptions<GetListAccountExnessQuery, GetListAccountExnessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListAccountExnessQuery, GetListAccountExnessQueryVariables>(GetListAccountExnessDocument, options);
      }
export function useGetListAccountExnessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListAccountExnessQuery, GetListAccountExnessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListAccountExnessQuery, GetListAccountExnessQueryVariables>(GetListAccountExnessDocument, options);
        }
export function useGetListAccountExnessSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetListAccountExnessQuery, GetListAccountExnessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetListAccountExnessQuery, GetListAccountExnessQueryVariables>(GetListAccountExnessDocument, options);
        }
export type GetListAccountExnessQueryHookResult = ReturnType<typeof useGetListAccountExnessQuery>;
export type GetListAccountExnessLazyQueryHookResult = ReturnType<typeof useGetListAccountExnessLazyQuery>;
export type GetListAccountExnessSuspenseQueryHookResult = ReturnType<typeof useGetListAccountExnessSuspenseQuery>;
export type GetListAccountExnessQueryResult = Apollo.QueryResult<GetListAccountExnessQuery, GetListAccountExnessQueryVariables>;
export const GetListOrderExnessDocument = gql`
    query GetListOrderExness($input: GetListOrderExnessInput!) {
  getListOrderExness(input: $input) {
    orders {
      orderId
      accountId
      type
      openTime
      openPrice
      closeTime
      closePrice
      volume
      profit
      sl
      tp
      commission
      instrument
    }
  }
}
    `;

/**
 * __useGetListOrderExnessQuery__
 *
 * To run a query within a React component, call `useGetListOrderExnessQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOrderExnessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOrderExnessQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListOrderExnessQuery(baseOptions: Apollo.QueryHookOptions<GetListOrderExnessQuery, GetListOrderExnessQueryVariables> & ({ variables: GetListOrderExnessQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOrderExnessQuery, GetListOrderExnessQueryVariables>(GetListOrderExnessDocument, options);
      }
export function useGetListOrderExnessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOrderExnessQuery, GetListOrderExnessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOrderExnessQuery, GetListOrderExnessQueryVariables>(GetListOrderExnessDocument, options);
        }
export function useGetListOrderExnessSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetListOrderExnessQuery, GetListOrderExnessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetListOrderExnessQuery, GetListOrderExnessQueryVariables>(GetListOrderExnessDocument, options);
        }
export type GetListOrderExnessQueryHookResult = ReturnType<typeof useGetListOrderExnessQuery>;
export type GetListOrderExnessLazyQueryHookResult = ReturnType<typeof useGetListOrderExnessLazyQuery>;
export type GetListOrderExnessSuspenseQueryHookResult = ReturnType<typeof useGetListOrderExnessSuspenseQuery>;
export type GetListOrderExnessQueryResult = Apollo.QueryResult<GetListOrderExnessQuery, GetListOrderExnessQueryVariables>;
export const GetListPriceDocument = gql`
    query GetListPrice($input: GetListPriceInput!) {
  getListPrice(input: $input) {
    items {
      a
      b
      t
      instrument
    }
    pagination {
      prevPage
      currPage
      nextPage
      itemTotal
      pageTotal
      pageSize
    }
  }
}
    `;

/**
 * __useGetListPriceQuery__
 *
 * To run a query within a React component, call `useGetListPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListPriceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListPriceQuery(baseOptions: Apollo.QueryHookOptions<GetListPriceQuery, GetListPriceQueryVariables> & ({ variables: GetListPriceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListPriceQuery, GetListPriceQueryVariables>(GetListPriceDocument, options);
      }
export function useGetListPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListPriceQuery, GetListPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListPriceQuery, GetListPriceQueryVariables>(GetListPriceDocument, options);
        }
export function useGetListPriceSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetListPriceQuery, GetListPriceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetListPriceQuery, GetListPriceQueryVariables>(GetListPriceDocument, options);
        }
export type GetListPriceQueryHookResult = ReturnType<typeof useGetListPriceQuery>;
export type GetListPriceLazyQueryHookResult = ReturnType<typeof useGetListPriceLazyQuery>;
export type GetListPriceSuspenseQueryHookResult = ReturnType<typeof useGetListPriceSuspenseQuery>;
export type GetListPriceQueryResult = Apollo.QueryResult<GetListPriceQuery, GetListPriceQueryVariables>;