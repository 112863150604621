import AuthLayout from '../components/_shared/layouts/auth'
import LoginPage from '../pages/auth/Login'
import HomeLayout from '../components/_shared/layouts/home'
import NotFoundPage from '../pages/exceptions/NotFound'
import HomePage from '../pages/home/Home'


interface IRoute {
    path: string,
    component: React.FC<any>,
    layout?: React.FC<any>
}

const publicRoutes: IRoute[] = [
    { path: 'login', component: LoginPage, layout: AuthLayout },
    { path: '/', component: HomePage, layout: HomeLayout },
    { path: '*', component: NotFoundPage }
]

const privateRoutes = [
    { path: '/' }
]

export { publicRoutes, privateRoutes }