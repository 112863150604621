import { useEffect } from 'react';
import './home.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthProvide';
import { useCheckLoginMutation } from '../../../graphql/schema';

function HomeLayout(props: any) {
  const auth = useAuth();
  const location = useLocation();
  const [checkLogin] = useCheckLoginMutation();
  const navigate = useNavigate();


  useEffect(() => {
    if (!auth?.token) {
      navigate('/login');
    } else {
      checkLogin({
        variables: {
          token: auth?.token || ''
        }
      }).then(res => {
        if (res.data?.checkToken !== true) {
          return navigate('/login');
        }
      })
    }
  }, [location.pathname]);

  return (
    <div className="main-layout">
      {props.children}
    </div>
  );
}

export default HomeLayout;
