import React, { useEffect, useState } from 'react';
import { Input, Button } from 'antd';
import { toast } from 'react-toastify';
import { useLoginMutation } from '../../graphql/schema';
import { useAuth } from '../../context/AuthProvide';
import './Login.css';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSubmiting, setIsSubmiting] = useState(false)
  const auth = useAuth();

  const [login, { error, data }] = useLoginMutation({
    variables: {
      input: {
        email,
        password,
      },
    }
  });

  useEffect(() => {
    auth.removeAuth();
  }, [])

  const handleLogin = async () => {
    setIsSubmiting(true);
    try {
      const { data, errors } = await login({
        variables: {
          input: {
            email,
            password,
          },
        },
      });
      if (!errors && data && data.login.token) {
        auth?.setAuth({
          token: data?.login.token,
          user: {
            id: data.login.id,
            email: data.login.email
          },
          exness: {
            exnessCookies: data.login.exnessCookies,
            loggedExnessAt: data.login.loggedExnessAt,
          }
        })
        window.location.href = '/';
      } else {
        toast.error(errors?.map(error => error.message).join('\n'))
      }
      setIsSubmiting(false);
    } catch (error) {
      setIsSubmiting(false);
      toast.error(`Error: ${error?.toString()}`);
    }
  };

  return (
    <div className="w-full max-w-xs mx-auto mt-8">
      <h2 className="text-2xl text-center mb-4">Login</h2>
      <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="email">
            Email
          </label>
          <Input
            id="email"
            required
            placeholder="Enter your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 text-sm font-bold mb-2 text-left" htmlFor="password">
            Password
          </label>
          <Input.Password
            id="password"
            placeholder="Enter your password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <Button type="primary" className="w-full mt-4 bg-blue-500" onClick={handleLogin} loading={isSubmiting}>
          Log In
        </Button>
      </form>
    </div>
  );
};

export default LoginPage;