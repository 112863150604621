import { InputNumber, Select, DatePicker, Button } from "antd";
import { useEffect, useRef, useState } from "react";
import { Instrument } from "../../../../graphql/schema";
import TradingChart from "./chart-wrapper/TradingChart";

type ChartConfig = {
  type: 'realtime' | 'old';
  startDate: Date;
  endDate: Date; 
  instrument: Instrument;
  maDurationSecond: number;
}

function ChartWrapper(props: any) {
  console.log('ChartWrapper');
  const [config, setConfig] = useState<ChartConfig>({
    type: 'realtime',
    instrument: Instrument.XAUUSD,
    startDate: new Date(),
    endDate: new Date(),
    maDurationSecond: 60,
  });
  const configRef = useRef(config);

  const handleApply = () => {
    setConfig({ ...configRef.current });
  }

  return (
    <>
      <TradingChart maDurationSecond={config.maDurationSecond} instrument={config.instrument} />
      <div className="fixed flex top-28 left-10 mr-10 p-2 shadow bg-white rounded">
        <Select
          defaultValue={config.instrument}
          className="ml-3"
          options={Object.values(Instrument).map(inst => ({ value: inst, label: inst }))}
          onChange={(v) => configRef.current.instrument = v as any }
        />
        {/* <RangePicker className="ml-3" /> */}
        <InputNumber className="ml-3" addonBefore="MA" addonAfter="(s)" readOnly={true} defaultValue={config.maDurationSecond} onChange={(v) => { if (v) configRef.current.maDurationSecond = v }} />
        <Button type="default" className="ml-3" onClick={handleApply}>Apply</Button>
      </div>
    </>
  );
}

export default ChartWrapper;