import React, { createContext, useState, ReactNode, useContext } from "react";

interface IAuth {
    user: {
        id: number;
        email: string;
    };
    token: string;
    exness: {
        exnessCookies?: any[] | null;
        loggedExnessAt?: string | null;
    }
}
interface TAuthContext extends IAuth {
    setAuth: (auth: IAuth) => void;
    removeAuth: () => void;
}

const getSessionAuth = () => {
    const auth = localStorage.getItem('_session') || '{}';
    return JSON.parse(auth)
};

const setSessionAuth = (auth: IAuth) => {
    localStorage.setItem('_session', JSON.stringify(auth));
};

const removeSessionAuth = () => {
    localStorage.removeItem('_session');
};

export const AuthContext = createContext<TAuthContext>(getSessionAuth());

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    console.log('AuthProvider')
    const auth = getSessionAuth();
    const [session, setSession] = useState<IAuth>(auth);

    const setAuth = (auth: IAuth) => {
        setSession(auth);
        auth && setSessionAuth(auth);
    };

    const removeAuth = () => {
        removeSessionAuth();
    };

    const { user, token, exness } = session;
    return (
        <AuthContext.Provider value={{ user, token, exness, setAuth, removeAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
}
