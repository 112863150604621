import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import { publicRoutes, privateRoutes } from './routes'
import { ToastContainer } from 'react-toastify';
import { useAuth } from './context/AuthProvide';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';


function App() {
  console.log('App')
  const auth = useAuth();

  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_API_ENDPOINT}/graphql`,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    },
    headers: {
      Authorization: `Bearer ${auth.token}`
    }
  })

  return (
    <ApolloProvider client={client}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            {
              publicRoutes.map((route, index) => {
                const Layout = route.layout;
                const Component = route.component;
                if (Layout) {
                  return <Route key={index} path={route.path} element={<Layout><Component /></Layout>} />
                } else {
                  return <Route key={index} path={route.path} element={<Component />} />
                }
              })},
          </Routes>
        </BrowserRouter>
        <ToastContainer position="bottom-right" theme="light" />
      </div>
    </ApolloProvider>
  );
}

export default App;
