import { useEffect, useRef, useState } from "react";
import { Tabs } from 'antd';
import { DollarOutlined, StockOutlined } from "@ant-design/icons";
import ChartWrapper from "../../components/pages/home/charts/ChartWrapper";
import CommandtWrapper from "../../components/pages/home/commands/CommandWrapper";

function HomePage() {
  console.log('HomePage');
  return (
    <>
      <Tabs
        defaultActiveKey="chart"
        size="large"
        items={[
          {
            key: `chart`,
            label: `Overview chart`,
            children: <ChartWrapper/>,
            icon: <StockOutlined />,
            style: { height: 'calc(100vh - 120px)', position: 'relative'}
          },
          {
            key: `command`,
            label: `Trading commands`,
            children: <CommandtWrapper />,
            icon: <DollarOutlined />,
            style: { height: 'calc(100vh - 120px)', position: 'relative'}
          }
        ]}
      />
    </>
  );
}

export default HomePage;