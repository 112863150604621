import { useEffect, useRef, useState } from "react";
import { DateTimeNumericAxis, DefaultPaletteProvider, EStrokePaletteMode, EllipsePointMarker, FastBubbleRenderableSeries, FastLineRenderableSeries, IPointMetadata, IZoomPanModifierOptions, MouseWheelZoomModifier, NumericAxis, SciChartSurface, TPointMarkerArgb, XyDataSeries, XyzDataSeries, ZoomExtentsModifier, ZoomPanModifier, parseColorToUIntArgb } from "scichart";
import { SciChartReact } from "scichart-react";
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from "../../../../../context/AuthProvide";
import { MovingAverage, MovingAverageExtremes } from "../../../../../../../../shared/utils/trade.util";
import { FilterOperator, Instrument, SortOrder, useGetListPriceLazyQuery } from "../../../../../graphql/schema";
import { LeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import { toast } from "react-toastify";

SciChartSurface.loadWasmFromCDN();
SciChartSurface.UseCommunityLicense();

const timeOffset = new Date().getTimezoneOffset();

interface TradingChartProps {
  maDurationSecond: number;
  instrument: Instrument;
}

enum ExtremePointType {
  MAX = 1,
  MIN = 2,
}

class BubblePaletteProvider extends DefaultPaletteProvider {
  constructor() {
    super();
    this.strokePaletteMode = EStrokePaletteMode.SOLID;
  }

  // This function is called for every data-point.
  overridePointMarkerArgb(xValue: number, yValue: number, index: number, opacity?: number | undefined, metadata?: IPointMetadata | undefined): TPointMarkerArgb {
    if (index === ExtremePointType.MAX) {
      return { stroke: parseColorToUIntArgb('#FF8C00'), fill: parseColorToUIntArgb('#FF8C00') }
    }
    if (index === ExtremePointType.MIN) {
      return { stroke: parseColorToUIntArgb('#FF8C00'), fill: parseColorToUIntArgb('#FF8C00') }
    }
    return { stroke: parseColorToUIntArgb('#FF8C00'), fill: parseColorToUIntArgb('#FF8C00') }
  }
}

function TradingChart(props: TradingChartProps) {
  const auth = useAuth();
  const [getListPrice] = useGetListPriceLazyQuery();
  const pricesDataSeriesRef = useRef<XyDataSeries | null>(null);
  const ma7DataSeriesRef = useRef<XyDataSeries | null>(null);
  const ma25DataSeriesRef = useRef<XyDataSeries | null>(null);
  const pointDataSeriesRef = useRef<XyzDataSeries>();
  const fromTimeRef = useRef<number>(Date.now());
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isWsConnecting, setIsWsConnecting] = useState<boolean>(false);
  const [isChartInitialized, setIsChartInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (isChartInitialized) {
      handleDrawOldPrices().then((oldPrices) => {
        handleDrawCurrentPrices(oldPrices);
      });
    }
  }, [props.maDurationSecond, props.instrument, isChartInitialized]);

  async function handleDrawOldPrices(toTime?: number) {
    setIsLoading(true);
    fromTimeRef.current = (toTime || Date.now()) - 6 * 60 * 60 * 1000;
    const { data, error } = await getListPrice({
      variables: {
        input: {
          filter: {
            and: [{
              operator: FilterOperator.eq,
              property: 'instrument',
              value: props.instrument
            }, {
              operator: FilterOperator.between,
              property: 't',
              value: [fromTimeRef.current, toTime || Date.now()]
            }]
          },
          sort: [{
            property: 't',
            order: SortOrder.ASC
          }],
          pageSize: 6 * 60 * 60 * 1000 * 3,
          pageNumber: 1,
        }
      }
    }).finally(() => setIsLoading(false));
    if (!error && data) {
      const ma7Calculator = new MovingAverage(props.maDurationSecond * 7, 'buy');
      const ma25Calculator = new MovingAverage(props.maDurationSecond * 25, 'buy');
      const maExtremesCalculator = new MovingAverageExtremes(60);
      if (data.getListPrice.items.length === 0) {
        toast.warn('Data is empty');
        return [];
      }
      const ma7Data: { xValues: number[]; yValues: number[] } = { xValues: [], yValues: [] };
      const ma25Data: { xValues: number[]; yValues: number[] } = { xValues: [], yValues: [] };
      const priceData: { xValues: number[]; yValues: number[] } = { xValues: [], yValues: [] };
      for (const price of data.getListPrice.items) {
        const { t, a, b } = price;
        ma7Calculator.updatePrice({ t, sell: a, buy: b });
        ma25Calculator.updatePrice({ t, sell: a, buy: b });

        if (ma7Calculator.isFull()) {
          const ma7NewPoint = ma7Calculator.value()!;
          if (ma7DataSeriesRef.current) {
            ma7Data.xValues.push((Number(ma7NewPoint.t) - timeOffset * 60 * 1000) / 1000);
            ma7Data.yValues.push(ma7NewPoint.price);
          }
        }
        if (ma25Calculator.isFull()) {
          const ma25NewPoint = ma25Calculator.value()!;
          if (ma25DataSeriesRef.current) {
            ma25Data.xValues.push((Number(ma25NewPoint.t) - timeOffset * 60 * 1000) / 1000);
            ma25Data.yValues.push(ma25NewPoint.price);
          }
        }

        if (ma7Calculator.isFull() && ma25Calculator.isFull()) {
          console.log('Diff: ', ma7Calculator.value().price - ma25Calculator.value().price)
          const extremes = maExtremesCalculator.updateMA(ma7Calculator.value(), ma25Calculator.value());
          if (extremes) {
            const { maxPoint, minPoint } = extremes;
            if (maxPoint) {
              pointDataSeriesRef.current?.append(
                (maxPoint.t - timeOffset * 60 * 1000) / 1000,
                maxPoint.price,
                30
              );
            }
            if (minPoint) {
              pointDataSeriesRef.current?.append(
                (minPoint.t - timeOffset * 60 * 1000) / 1000,
                minPoint.price,
                30
              );
            }
          }
        }

        if (pricesDataSeriesRef.current) {
          priceData.xValues.push((Number(t) - timeOffset * 60 * 1000) / 1000);
          priceData.yValues.push(b);
        }
      }

      if (ma7DataSeriesRef.current?.count()) {
        ma7DataSeriesRef.current?.insertRange(
          0,
          ma7Data.xValues,
          ma7Data.yValues,
        )
      } else {
        ma7DataSeriesRef.current?.appendRange(
          ma7Data.xValues,
          ma7Data.yValues,
        )
      }
      if (ma25DataSeriesRef.current?.count()) {
        ma25DataSeriesRef.current?.insertRange(
          0,
          ma25Data.xValues,
          ma25Data.yValues,
        )
      } else {
        ma25DataSeriesRef.current?.appendRange(
          ma25Data.xValues,
          ma25Data.yValues,
        )
      }
      if (pricesDataSeriesRef.current?.count()) {
        pricesDataSeriesRef.current?.insertRange(
          0,
          priceData.xValues,
          priceData.yValues,
        )
      } else {
        pricesDataSeriesRef.current?.appendRange(
          priceData.xValues,
          priceData.yValues,
        )
      }
    } else {
      toast.error('Failed to load data', {
        data: error,
      });
    }

    return data?.getListPrice.items || [];
  }

  function handleDrawCurrentPrices(oldPrices: {
    a: number;
    b: number;
    t: number;
  }[]) {
    if (auth?.exness?.exnessCookies && auth?.exness?.loggedExnessAt) {
      const jwt = auth.exness.exnessCookies.find(c => c.name === 'JWT').value;
      setIsWsConnecting(true);
      const ma7Calculator = new MovingAverage(props.maDurationSecond * 7, 'buy');
      const ma25Calculator = new MovingAverage(props.maDurationSecond * 25, 'buy');
      for (const price of oldPrices) {
        const { t, a, b } = price;
        ma7Calculator.updatePrice({ t, sell: a, buy: b });
        ma25Calculator.updatePrice({ t, sell: a, buy: b });
      }
      const ws = new WebSocket(
        `wss://rtapi-sg.eccweb.mobi/rtapi/mt5/trial8/v1/ws/ticks/accounts/79218550`, ['exness-ws-protocol', jwt]
      );
      ws.onopen = () => {
        ws.send(
          `{"subscribe":{"event":"ticks","instruments":["${props.instrument}"]},"id":"${uuidv4()}"}`,
        );
      };
      ws.onerror = (error) => {
        console.error('Failed to connect to Exness websocket', error);
        setIsWsConnecting(false);
      };
      ws.onclose = (error) => {
        console.error('Closed websocket connection', error);
        setIsWsConnecting(false);
      };
      ws.onmessage = (message) => {
        setIsWsConnecting(false);
        const price = JSON.parse(message.data);
        const { t, a, b } = price;
        if (t && a && b && !isLoading) {
          ma7Calculator.updatePrice({ t, sell: a, buy: b });
          ma25Calculator.updatePrice({ t, sell: a, buy: b });
          if (ma7Calculator.isFull()) {
            const ma7NewPoint = ma7Calculator.value()!;
            if (ma7DataSeriesRef.current) {
              ma7DataSeriesRef.current.append(
                (Number(ma7NewPoint.t) - timeOffset * 60 * 1000) / 1000,
                ma7NewPoint.price
              )
            }
          }
          if (ma25Calculator.isFull()) {
            console.log('Diff: ', ma7Calculator.value().price - ma25Calculator.value().price)
            const ma25NewPoint = ma25Calculator.value()!;
            if (ma25DataSeriesRef.current) {
              ma25DataSeriesRef.current.append(
                (Number(ma25NewPoint.t) - timeOffset * 60 * 1000) / 1000,
                ma25NewPoint.price
              )
            }
          }
          if (pricesDataSeriesRef.current) {
            pricesDataSeriesRef.current.append(
              (Number(t) - timeOffset * 60 * 1000) / 1000,
              Number(b)
            )
          }
        }
      };
      return ws;
    } else {
      window.location.href = '/login';
    }
  }

  async function initChart(rootElement: string | HTMLDivElement) {
    const { sciChartSurface, wasmContext } = await SciChartSurface.create(rootElement);
    const xAxis = new DateTimeNumericAxis(wasmContext, { autoTicks: true });
    const yAxis = new NumericAxis(wasmContext, { autoTicks: true });

    sciChartSurface.xAxes.add(xAxis);
    sciChartSurface.yAxes.add(yAxis);

    const priceSeries = new FastLineRenderableSeries(wasmContext, { stroke: "white", strokeThickness: 1, opacity: 0.5 });
    pricesDataSeriesRef.current = new XyDataSeries(wasmContext, { dataSeriesName: "Price" });
    priceSeries.dataSeries = pricesDataSeriesRef.current;

    const ma25Series = new FastLineRenderableSeries(wasmContext, { stroke: "yellow", strokeThickness: 3 });
    ma25DataSeriesRef.current = new XyDataSeries(wasmContext, { dataSeriesName: "MA25" });
    ma25Series.dataSeries = ma25DataSeriesRef.current;

    const ma7Series = new FastLineRenderableSeries(wasmContext, { stroke: "red", strokeThickness: 3 });
    ma7DataSeriesRef.current = new XyDataSeries(wasmContext, { dataSeriesName: "MA7" });
    ma7Series.dataSeries = ma7DataSeriesRef.current;

    const pointSeries = new FastBubbleRenderableSeries(wasmContext, {
      opacity: 0.6,
      pointMarker: new EllipsePointMarker(wasmContext, {
        width: 64,
        height: 64,
        strokeThickness: 4,
        stroke: '#ffffff',
        fill: "red",
      }),
      paletteProvider: new BubblePaletteProvider()
    })
    const pointData = new XyzDataSeries(wasmContext, { dataSeriesName: "Point" });
    pointDataSeriesRef.current = pointData;
    pointSeries.dataSeries = pointData;

    sciChartSurface.renderableSeries.add(priceSeries, pointSeries, ma7Series, ma25Series);
    sciChartSurface.chartModifiers.add(
      new ZoomPanModifier({ enableZoom: true }),
      new MouseWheelZoomModifier(),
      new ZoomExtentsModifier()
    );

    setIsChartInitialized(true);

    return { sciChartSurface };
  }

  return (
    <>
      <>
        <SciChartReact
          style={{ width: '100%', height: "100%" }}
          initChart={initChart}
        />
        <div className="absolute top-1/2 left-2">
          <Button shape="circle" className="bg-white opacity-60" icon={<LeftOutlined />} onClick={() => handleDrawOldPrices(fromTimeRef.current)}></Button>
        </div>
      </>
      {
        (isWsConnecting || isLoading) && (
          <div className="h-full w-full flex justify-center items-center absolute top-0">
            <Spin indicator={<LoadingOutlined style={{ fontSize: 36, color: 'white' }} spin />} />
          </div>
        )
      }
    </>
  );
}

export default TradingChart;