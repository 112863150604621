import { Select, Button, Spin, Card, Empty, Modal, Collapse } from "antd";
import { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import { AccounOutput, OrderExnessItemOutput, useGetListAccountExnessLazyQuery, useGetListOrderExnessLazyQuery } from "../../../../graphql/schema";
import { LoadingOutlined } from "@ant-design/icons";
import OrderChart from "./command-wrapper/OrderChart";
import CommandGroup from "./command-wrapper/CommandGroup";


function CommandtWrapper(props: any) {
  const [getListAccount] = useGetListAccountExnessLazyQuery();
  const previousDay = useRef<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<AccounOutput | null>(null);
  const [selectedOrder, setSelectedOrder] = useState<OrderExnessItemOutput | null>(null);
  const [accounts, setAccounts] = useState<AccounOutput[]>([]);
  const [renderCount, setRenderCount] = useState<number>(0);
  const orderGroupsRef = useRef<Record<string, OrderExnessItemOutput[]>>({});

  useEffect(() => {
    setIsLoading(true);
    getListAccount().then((accountRes) => {
      if (accountRes.data?.getListAccountExness) {
        setAccounts(accountRes.data.getListAccountExness.accounts.filter(a => a.config))
      }
    }).finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    const today = moment().tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD');
    previousDay.current = today;
    orderGroupsRef.current = {
      [today]: []
    };
    setRenderCount(renderCount+1);
  }, [selectedAccount])

  const handleCalculateSummaryResult = () => {
    console.log('handleCalculateSummaryResult: ', Object.keys(orderGroupsRef.current).length)
    const orders = Object.keys(orderGroupsRef.current).map(key => orderGroupsRef.current[key]).flat();
    const profit = orders.reduce((profit, order) => profit + order.profit, 0);
    const winCount = orders.filter(o => o.profit >= 0).length || 0;
    const loseCount = orders.filter(o => o.profit < 0).length || 0;
    return {
      profit, winCount, loseCount
    }
  }

  const handleLoadMore = () => {
    previousDay.current = moment(previousDay.current).subtract(1, 'day').tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD');
    orderGroupsRef.current[previousDay.current] = [];
    setRenderCount(renderCount+1);
  }

  const handleUpdateDailyResult = (day: string, orders: OrderExnessItemOutput[]) => {
    orderGroupsRef.current[day] = orders;
    console.log('handleUpdateDailyResult', Object.keys(orderGroupsRef.current).length);
    setRenderCount(renderCount+1);
  }

  return (
    <>
      {isLoading ? (
        <div className="h-full w-full flex justify-center items-center">
          <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
        </div>
      ) : (
        <div className="w-full h-full relative flex justify-center items-center bg-[#f0f2f5] overflow-y-scroll">
          <div className="h-full w-full max-w-screen-md relative">
            <Select
              className="w-full h-14 m-3 mb-5"
              defaultValue={accounts.find(acc => acc.isActive)?.accountNumber}
              value={selectedAccount?.accountNumber || null}
              placeholder="------ Select account------"
              onChange={(v) => setSelectedAccount(accounts.find(a => a.accountNumber === v) || null)}
              options={accounts.map((account) => ({
                value: account.accountNumber,
                label: `${account.name} #${account.accountNumber} - ${account.config?.instrument} ${!account.isReal ? ' (Test)' : ''}`
              }))}
            />
            <Card
              className="m-3 w-full"
              title={
                <b>
                  <span className="mr-1">Summary: </span>
                  <span className={handleCalculateSummaryResult().profit < 0 ? 'text-red-700' : 'text-green-700'}>{handleCalculateSummaryResult().profit.toFixed(2)} USD</span>
                  <span className="text-green-700 ml-5">{handleCalculateSummaryResult().winCount} Win</span>
                  <span className="ml-2 mr-2"> - </span>
                  <span className="text-red-700">{handleCalculateSummaryResult().loseCount} Lose</span>
                </b>
              }
              bordered={true}
            >
              {
                selectedAccount?.config
                  ? <>
                    {
                      Object.keys(selectedAccount?.config).filter(key => !key.startsWith('_') && key !== 'sideWayOptions').map((key) => (
                        <p key={key}>
                          <span className="text-left">{key}:</span>
                          <b className="text-right ml-4">{(selectedAccount.config as any)[key]}</b>
                        </p>
                      ))
                    }
                  </> : (<></>)
              }
            </Card>
            {
              selectedAccount && (
                <>
                  <>
                    {
                      Object.keys(orderGroupsRef.current).map((day, i) => (
                        <CommandGroup 
                          key={day} day={day} 
                          exnessAccountId={selectedAccount?.accountNumber || '0'} 
                          onLoaded={handleUpdateDailyResult} 
                          onViewChart={(order) => setSelectedOrder(order)}
                        />
                      ))
                    }
                  </>
                  <Button type="dashed" className="m-3 w-full" onClick={handleLoadMore}> Load more </Button>
                </>
              )
            }
          </div>
          {
            selectedOrder
              ? (
                <Modal
                  title={`Order #${selectedOrder?.orderId} - ${selectedOrder.instrument} - ${selectedAccount?.config?.durationInSecond}s`}
                  open={!!selectedOrder}
                  onCancel={() => { setSelectedOrder(null) }}
                  okButtonProps={{ hidden: true }}
                  cancelButtonProps={{ hidden: true }}
                  width={"calc(100% - 500px)"}
                  bodyStyle={{ height: '800px' }}
                >
                  <OrderChart order={selectedOrder} durationInSecond={selectedAccount?.config?.durationInSecond!} />
                </Modal>
              ) : <></>
          }
        </div>
      )}
    </>
  );
}

export default CommandtWrapper;