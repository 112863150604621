
import { AuthProvider } from '../../../context/AuthProvide';
import './auth.css';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';

function AuthLayout(props: any) {
  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_API_ENDPOINT}/graphql`,
    cache: new InMemoryCache(),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
      }
    },
  })

  return (
    <ApolloProvider client={client}>
      <div className="auth-layout">
        {props.children}
      </div>
    </ApolloProvider>
  );
}

export default AuthLayout;
