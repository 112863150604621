export enum Instrument {
  BTCUSD = 'BTCUSD',
  XAUUSD = 'XAUUSD',
  USOIL = 'USOIL',
}

export enum TradeOrderType {
  BUY = 'buy',
  SELL = 'sell',
}

export enum OrderStatus {
  WAITING = 'waiting',
  CANCEL = 'cancel',
  OPENED = 'opened',
}

export interface Price {
  t: number;
  sell: number;
  buy: number;
}

export interface Point {
  t: number;
  price: number;
}

export class TimeLimitedList<T extends { t: number }> {
  private readonly maxSecDiff: number;
  public currentMsDiff: number;
  private isSkipIdlePeriod: boolean;
  private minIdlePeriodSecond: number;
  private items: T[];
  constructor(maxSecondDiff: number, options?: { isSkipIdlePeriod: boolean, minIdlePeriodSecond: number }) {
    this.maxSecDiff = maxSecondDiff;
    this.currentMsDiff = 0;
    this.isSkipIdlePeriod = options?.isSkipIdlePeriod || false;
    this.minIdlePeriodSecond = options?.isSkipIdlePeriod ? options?.minIdlePeriodSecond : 15 * 60;
    this.items = [];
  }

  add(item: T) {
    const removedItems: T[] = [];
    if (this.items.length > 0) {
      const lastItem = this.items[this.items.length - 1];
      const diff = this.getDiff(item, lastItem);
      this.currentMsDiff += diff;
      while (this.items.length >= 2 && this.currentMsDiff - this.getDiff(this.items[1], this.items[0]) > this.maxSecDiff * 1000) {
        this.currentMsDiff -= this.getDiff(this.items[1], this.items[0]);
        const removedItem = this.items.shift();
        removedItem && removedItems.push(removedItem);
      }
    }
    this.items.push(item);
    return removedItems;
  }

  private getDiff(item1: T, item2: T){
    let diff = item2.t > item1.t ? item2.t - item1.t :  item1.t - item2.t;
    if (this.isSkipIdlePeriod == true && diff > this.minIdlePeriodSecond * 1000) {
      diff = 0;
    }
    return diff;
  }

  empty() {
    this.items = [];
  }

  getLength() {
    return this.items.length;
  }

  getItems() {
    return this.items;
  }

  isFull() {
    return (
      this.items.length > 2 && this.currentMsDiff >= this.maxSecDiff * 1000
    );
  }
}

export class MovingAverage {
  public readonly preiodSecond: number;
  private applyTo: 'sell' | 'buy';
  public priceList: TimeLimitedList<Price>;
  public valueList: TimeLimitedList<Point>;

  constructor(preiodSecond: number, applyTo: 'sell' | 'buy') {
    this.preiodSecond = preiodSecond;
    this.applyTo = applyTo;
    this.priceList = new TimeLimitedList(preiodSecond, {
      isSkipIdlePeriod: true,
      minIdlePeriodSecond: 15 * 60,
    });
    this.valueList = new TimeLimitedList(preiodSecond, {
      isSkipIdlePeriod: true,
      minIdlePeriodSecond: 15 * 60,
    });
  }

  updatePrice(price: Price) {
    this.priceList.add(price);
    let sum = 0;
    const prices = this.priceList.getItems();
    if (prices.length > 0) {
      for (const price of prices) {
        if (this.applyTo === 'buy') {
          sum += price.buy;
        }
        if (this.applyTo === 'sell') {
          sum += price.sell;
        }
      }
      this.valueList.add({
        t: price.t,
        price: sum / prices.length,
      });
    }
  }

  clear() {
    this.valueList.empty();
  }

  value() {
    return this.valueList.getItems()[this.valueList.getLength() - 1];
  }

  oldValue() {
    return this.valueList.getItems()[this.valueList.getLength() - 2];
  }

  values() {
    return [...this.valueList.getItems()];
  }

  prices() {
    return [...this.priceList.getItems()];
  }

  isFull() {
    return this.priceList.isFull();
  }
}

export class LineSegment {
  start: Point;
  end: Point;

  constructor(start: Point, end: Point) {
    this.start = start;
    this.end = end;
  }

  intersects(other: LineSegment): boolean {
    // Kiểm tra xem hai đoạn thẳng có cắt nhau không
    const o1 = this.orientation(this.start, this.end, other.start);
    const o2 = this.orientation(this.start, this.end, other.end);
    const o3 = this.orientation(other.start, other.end, this.start);
    const o4 = this.orientation(other.start, other.end, this.end);

    if (o1 !== o2 && o3 !== o4) {
      return true;
    }

    // Trường hợp đặc biệt khi các đoạn thẳng nằm trên cùng một đường thẳng
    if (o1 === 0 && this.onSegment(this.start, other.start, this.end))
      return true;
    if (o2 === 0 && this.onSegment(this.start, other.end, this.end))
      return true;
    if (o3 === 0 && this.onSegment(other.start, this.start, other.end))
      return true;
    if (o4 === 0 && this.onSegment(other.start, this.end, other.end))
      return true;

    return false;
  }

  orientation(p: Point, q: Point, r: Point): number {
    const val =
      (q.price - p.price) * (r.t - q.t) - (q.t - p.t) * (r.price - q.price);
    if (val === 0) return 0; // Các điểm thẳng hàng
    return val > 0 ? 1 : 2; // 1: Ngược chiều kim đồng hồ, 2: Theo chiều kim đồng hồ
  }

  onSegment(p: Point, q: Point, r: Point): boolean {
    if (
      q.t <= Math.max(p.t, r.t) &&
      q.t >= Math.min(p.t, r.t) &&
      q.price <= Math.max(p.price, r.price) &&
      q.price >= Math.min(p.price, r.price)
    ) {
      return true;
    }
    return false;
  }

  angleWithTimeAxis(): number {
    const dt = this.end.t - this.start.t;
    const dp = this.end.price - this.start.price;
    return Math.atan2(dp, dt) * (180 / Math.PI); // Góc tính bằng độ
  }
}

export class MovingAverageExtremes {
  private ma7List: TimeLimitedList<Point>;
  private prevMa7Point?: Point;
  private prevMa25Point?: Point;
  private maxPoint?: Point;
  private minPoint?: Point;
  private currentMaxPrice: number;
  private currentMinPrice: number;

  constructor(timeWindowSecond: number) {
    this.ma7List = new TimeLimitedList<Point>(timeWindowSecond * 2);
    this.maxPoint = undefined;
    this.minPoint = undefined;
    this.currentMaxPrice = -1;
    this.currentMinPrice = 99999999999;
  }

  /**
   * 
   * @param maPoint 
   * @returns Return the newest minimum (maxnimun) point that was just discovered
   */
  updateMA(ma7Point: Point, ma25Point: Point) {
    const removedPoints = this.ma7List.add(ma7Point);
    if (removedPoints.length > 0) {
      for (const removedPoint of removedPoints) {
        if (removedPoint.price >= this.currentMaxPrice) {
          this.currentMaxPrice = this.ma7List.getItems()[0]?.price || -1;
        }
        if (removedPoint.price <= this.currentMinPrice) {
          this.currentMinPrice = this.ma7List.getItems()[0]?.price || 99999999999;
        }
      }
    }

    if (ma7Point.price > this.currentMaxPrice) {
      this.currentMaxPrice = ma7Point.price;
    }
    if (ma7Point.price < this.currentMinPrice) {
      this.currentMinPrice = ma7Point.price;
    }

    const centerIndex = Math.floor(this.ma7List.getLength() / 2);
    const centerPoint = this.ma7List.getItems()[centerIndex];
    if (
      centerPoint &&
      centerPoint.price === this.currentMaxPrice &&
      centerPoint.price !== this.maxPoint?.price &&
      (!this.maxPoint || this.maxPoint.price < centerPoint.price) &&
      ma7Point.price > ma25Point.price
    ) {
      this.maxPoint = centerPoint;
    } else if (
      centerPoint &&
      centerPoint.price === this.currentMinPrice &&
      centerPoint.price !== this.minPoint?.price && 
      (!this.minPoint || this.minPoint.price > centerPoint.price) &&
      ma7Point.price < ma25Point.price
    ) {
      this.minPoint = centerPoint;
    }

    let result: {
      minPoint?: Point;
      maxPoint?: Point;
    } | undefined = undefined;

    if (
      this.prevMa7Point &&
      this.prevMa25Point &&
      (ma7Point.price - ma25Point.price) * (this.prevMa7Point.price - this.prevMa25Point.price) <= 0) {

      result = {
        minPoint: this.minPoint,
        maxPoint: this.maxPoint, 
      };

      this.minPoint = undefined;
      this.maxPoint = undefined;
    }

    this.prevMa7Point = { ...ma7Point };
    this.prevMa25Point = { ...ma25Point };

    return result;
  }
}