import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet } from 'react-helmet'
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthProvide';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
    {/* {
      process.env.NODE_ENV === 'production'
        ? (
          <Helmet>
            <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
          </Helmet>
        )
        : <></>
    } */}
    <AuthProvider>
      <App />
    </AuthProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
