import { Button, Spin, Card, Empty, Collapse } from "antd";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { OrderExnessItemOutput, useGetListOrderExnessLazyQuery } from "../../../../../graphql/schema";
import { LoadingOutlined } from "@ant-design/icons";

interface CommandGroupProps {
    day: string;
    exnessAccountId: string;
    onLoaded: (day: string, order: OrderExnessItemOutput[]) => void
    onViewChart: (order: OrderExnessItemOutput) => void
}

function CommandGroup(props: CommandGroupProps) {
    const [getListOrder] = useGetListOrderExnessLazyQuery();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [orders, setOrders] = useState<OrderExnessItemOutput[]>([]);

    useEffect(() => {
        setOrders([]);
        const day = moment(props.day).tz('Asia/Ho_Chi_Minh');
        const fromTime = day.startOf('day').toISOString();
        const toTime = day.endOf('day').toISOString();
        setIsLoading(true);
        getListOrder({
            variables: {
                input: {
                    exnessAccountId: props.exnessAccountId,
                    fromTime,
                    toTime,
                }
            }
        }).then((orderRes) => {
            if (orderRes.data?.getListOrderExness) {
                const orders = orderRes.data.getListOrderExness.orders;
                setOrders(orders);
                props.onLoaded(props.day, orders);
            }
        }).finally(() => setIsLoading(false))
    }, [props.exnessAccountId, props.day]);

    const handleCalculateResult = () => {
        const profit = orders.reduce((profit, order) => profit + order.profit, 0);
        const winCount = orders.filter(o => o.profit >= 0).length || 0;
        const loseCount = orders.filter(o => o.profit < 0).length || 0;
        return {
            profit, winCount, loseCount
        }
    }

    return (
        <>
            <Collapse
                defaultActiveKey={props.day}
                className="m-3 w-full"
                items={[{
                    key: props.day,
                    label: <b>
                        <span className="mr-2">{props.day}</span>
                        <span className={handleCalculateResult().profit < 0 ? 'text-red-700' : 'text-green-700'}>{handleCalculateResult().profit.toFixed(2)} USD</span>
                        <span className="text-green-700 ml-5">{handleCalculateResult().winCount} Win</span>
                        <span className="ml-2 mr-2"> - </span>
                        <span className="text-red-700">{handleCalculateResult().loseCount} Lose</span>
                    </b>,
                    headerClass: "bg-white font-bold",
                    children: <>
                        {
                            isLoading
                                ? (
                                    <div className="h-full w-full flex justify-center items-center">
                                        <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
                                    </div>
                                )
                                : (
                                    <div className="relative pt-3 pb-3">
                                        {
                                            orders.length > 0
                                                ? orders.map((order, i) => (
                                                    <Card
                                                        key={i}
                                                        className="m-3 w-auto"
                                                        title={
                                                            <span>
                                                                <span>Order #{order.orderId}</span>
                                                                <span className="ml-1">({order.type})</span>
                                                                <span className="ml-3">{order.profit.toFixed(2)} USD</span>
                                                            </span>
                                                        }
                                                        bordered={true}
                                                        extra={<Button style={{ backgroundColor: 'white' }} onClick={() => { props.onViewChart(order) }}>View Chart</Button>}
                                                        styles={{ title: { textAlign: 'left', color: order.profit >= 0 ? 'green' : 'red' } }}
                                                    >
                                                        <p className="text-left font-bold">
                                                            <span>Open/Close: {order.openPrice.toFixed(3)} / {order.closePrice.toFixed(3)}</span>
                                                            <span className="ml-2">SL: {order.sl}</span>
                                                            <span className="ml-2">Open at: {moment(order.openTime).tz('Asia/Ho_Chi_Minh').format('YYYY-MM-DD HH:mm:SS')}</span>
                                                            <span className="ml-1">({moment.duration(moment().diff(moment(order.closeTime))).humanize(true)})</span>
                                                        </p>
                                                    </Card>
                                                ))
                                                : <Empty />
                                        }
                                    </div>
                                )
                        }
                    </>
                }]}>
            </Collapse>
        </>
    );
}

export default CommandGroup;